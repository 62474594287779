#newsLetter {
  header#header {
    position: relative;

    ul#nav-about {
      @include nav-about;
    }

    @media(max-width: 767px) {
      margin-bottom: 0 !important;

    }
  }

  .banner {
    background-color: $link-blue;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(0deg, rgba(37, 47, 65, 0) 0%, rgba(30, 54, 96, 0.45) 100%);
    }

    @media(min-width: 767px) {
      height: 600px;
    }
  }

  .content {
    .container {
      margin-bottom: 32px;

      &.team-member-cards {
        @media(min-width:767px) {
          &.half {
            width: 50%;
            float: left;
          }
        }

      }
    }

    h1{
      text-align: center;
      font-size: 31px;
      margin-bottom: 21px;
    }

    h3 {
      @include project-title($ba-dark-gray);
      position: relative;
      margin-bottom: $space-md;
    }

    h4 {
      margin-bottom: $space-md;
    }

    form {
      @media(min-width:992px){
        padding: $space-lg;
        padding-top: $space-md;
      }
      h5 {
        text-transform: capitalize;
        font-size: 24px;
      }

      h4{
        text-align: center;
        font-size: 14px;
      }

      h3 {
        @include h3-font($dark-gray, $din-bold);
      }

      padding-top: 32px;
      position: relative;
      z-index: 1000;

      p {
        position: relative;
        width: 80%;
        margin: auto;
        i {
          position: absolute;
          left: 32px;
          top: 45px;
          transform: scale(1.5);

          @media(max-width: 340px) {
            top: 95px;
            display: none;
          }
        }

        button {
          border-radius: 4px;
          height: 56px;
          border: 2px solid #b8c1cb;
          @media(min-width: 521px) {
            position: absolute;
            right: 4px;
            top: 27px;
            width:90px;
          }
        }
      }

      input {
        width: 80%;
        &[type="email"]{
          padding-left: 64px;
        }

        &[type="submit"] {
          width: 200px;
          border-radius: initial;
          margin-top: $space-md;
        }
      }

      .socials {
        margin: $space-lg 0;
        background-color: #F0F0F0;
        padding: 8px 32px;
        border-radius: 4px;
        border: 8px solid $white;

        .links {
          display: flex;
          justify-content: center;
          align-items: center;

          a {
            color: $light-gray;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 16px 0;
            border-radius: 4px;
            margin-bottom: 16px;
            text-decoration: none;
            width: 100%;
            transition: all .5s ease-in-out;

            &:hover {
              color: $link-blue;

              label,
              i {
                color: $link-blue;
              }

              box-shadow: 0 5px 8px 0 rgba(0,0,0,0.05);
            }

            label {
              margin: auto;
              margin-top: $space-md;
              transition: all .5s ease-in-out;
            }

            i {
              text-align: center;
              transform: scale(1.2);
              transition: all .5s ease-in-out;

              &.hide {
                display: none;
              }
            }

            &:hover {
              color: $ba-gray;
            }
          }
        }
      }
    }

    .details {
      padding: 15px;
      background-color: #FAFAFA;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.06);
      position: relative;

      .down-arrow {
        position: absolute;
        right: 64px;
        top: 32px;
        left: initial;

        i {
          color: $ba-gray;
        }
      }

      @media(min-width: 992px) {
        padding: 30px 60px;
      }

      p {
        text-align: justify;
      }

      @media(min-width: 767px) {
        margin-top: -$space-xl;

      }

      @media(min-width: 992px) {}
    }
  }

  .main-content {
    margin: $space-lg 0;
  }

  @include page-images;
}