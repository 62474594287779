:focus {
  outline: none;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}

a {
  outline: 0;
  text-decoration: none;
  color: $link-blue;

  &.link-overlay {
    @include link-overlay;
  }
  &:hover {
    color: $dark-blue;
  }
}

p {
  @include p-base();
}

h4{
  @include h4-caps(14px, $ba-dark-gray, $din-medium, 1px);
}
h5 {
  @include h5-all-caps-base();
}

.sk-cube-grid{
  position: fixed;
  height: 120px;
  width: 200px;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  z-index: 9999999999;
  opacity: 1;
  transition: all .5s ease-in-out;

  div{
    background-color: white !important;
  }

  &.light{
    filter: brightness(60%);
  }

  &.loaded{
    opacity: 0;
    z-index: -1;
  }
}

body {
  font-family: $nunito-reg;
  position: relative;
  max-height: 100vh;
  overflow-x: hidden;
  opacity: 0;

  &.fadeIn{
    min-height: 100vh;
    max-height: initial;
  }

  &.dark {
    background-color: $charcoal;
  }

  &.light {
    background-color: $background-gray;
  }

  &#error {
    overflow: hidden;

    #nav-about {
      display: none;
    }

    main {
      padding: 0;
      position: relative;

      .bg {
        @include bg-full-screen;
        filter: grayscale(100%);
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 10;

        .error-message {
          padding: $space-md;
          text-align: center;
          background-color: rgba($black, 0.7);
          color: $white;
          position: relative;
          z-index: 100;

          h2,
          a {
            color: $white;
            margin-bottom: $space-md;
            padding: 16px 32px;
            margin-bottom: 0;
          }
        }

      }
    }

    footer#footer {
      display: none;
    }
  }

  main {
    // padding-bottom: 3*$space-lg;
    position: relative;
    z-index: -1;
  }

  &.fadeIn{
    main{
      z-index: 1;
    }
  }

}

header#header {
  position: relative;
  width: 100%;
  left: 0;
  z-index: 999;
  opacity: 1;
  transition: all .5s ease-in-out;
  display: flex;
  justify-content: space-between;
  margin: 32px 0;
  height: 91px;
  padding: 0 32px;

  // background: linear-gradient(to bottom, #5d5d8c -150%, rgba(193,191,234,0) 100%);
  #header-logo {
    position: relative;
    display: flex;
    align-items: center;
    z-index: 3;
    text-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    top: -34px;
    left: -16px;

    @media (min-width:991px) and (max-width:1024px){
      top: -2px;
    }

    @include media-breakpoint-up(lg) {
      svg {
        width: 275px;
      }
    }

    @include media-breakpoint-down(lg) {
      svg {
        width: 230px;
      }
    }
    @include media-breakpoint-down(md){
      svg{
        width: 162px;
      }
    }
  }

  &.dark {
    @include header-mobile-styles;
    position: absolute;
    top: 0;
    left: 0;
    #header-logo{
      svg {
        filter: drop-shadow(0 1px 2px rgba(0,0,0,0.30));  
        polygon,
        polyline,
        path,
        g {
          color: $white !important;
          fill: $white !important;
        }
      }
    }
    @include media-breakpoint-down(sm){
      background-color:#4b4b4b;
      height: 70px;
      #header-logo{
        top: 3px !important;
      }
    }
  }

  &.light {
    // #header-logo {
    //   left: -41px;
    // }
    // @extend .container;
    // @include header-light-ipad-styles;
    @include header-mobile-styles;
    background: transparent;
    #mobile-nav-toggle {
      span {
        background: $ba-dark-gray;
      }
    }

    #header-logo{
      svg {
        polygon,
        polyline,
        path,
        g {
          color: $ba-dark-gray !important;
          fill: $ba-dark-gray !important;
        }
      }
    }
    @include media-breakpoint-down(sm){
      height: 70px !important;
      margin-bottom: 32px !important;
      #header-logo{
        top: 3px !important;
      }
    }
  }

  &.home{
    @media(max-width: 767px){
      position: absolute;
      top:0;
      left:0;
      #header-logo{
        svg {
          filter: drop-shadow(0 1px 2px rgba(0,0,0,0.30));  
          polygon,
          polyline,
          path,
          g {
            color: $white !important;
            fill: $white !important;
          }
        }
      }
      background-color: transparent;
    }
  }


  ul#nav-desktop {
    position: relative;
    z-index: 4;
    margin: 0;
    right: 0;
    top: -10px;
    letter-spacing: 3px;
    text-transform: uppercase;
    font-family: $din-light;
    padding: 0;
    list-style: none;
    transition: all .5s ease-in-out;
    display: flex;
    align-items: center;

    &.dark {
      li {
        @include menu($white);

        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
        font-family: $din-light;
        // margin: 6px 0;
        color: $white;

        // &:first-child {
        //   margin-left: 6px;
        // }

        &:hover, &:focus, &:active, &.active {
          color: $white;
          text-decoration: none;
          background-color: rgba(0, 0, 0, .5);
        }
      }
    }

    &.light {
      li {
        @include menu($ba-dark-gray);
        color: $ba-dark-gray;

        &.active {
          background-color: $white;
        }

        &:hover{
          background-color: $white;
        }

      }
    }

    a {
      transition: all .4s ease-in;
      line-height: 20px;
      // border-bottom: 2px solid transparent;
      text-decoration: none;
    }

    li {

      display: inline-flex;
      align-items: center;
      padding: 8px 16px;
      transition: all .5s ease-in-out;

      &:last-of-type {
        border-right: none;
      }

    }
  }

  &.active {
    #nav-desktop {
      opacity: 0;
    }
  }

  @media(min-width: 1025px) {
    margin: 64px 0;
    padding: 0 64px;

    #header-logo {
      display: block;
    }

    ul#nav-desktop {
      li {
        margin-top: -56px;
      }
    }
  }
}

#footer-wrapper {
  position: absolute;
  bottom: 0;
  z-index: 3;
  width: 100%;
}

.mobile-menu-container {
  background-color: inherit;
  z-index: 101;
  position: absolute;
  height: 100vh;
  width: 100%;
  padding-top: 80px;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: all .25s ease-in-out;
  transform: translateX(-100%);

  &.open {
    transform: translateX(0);
    transition: all .25s ease-in-out;
  }

  .mobile-menu {
    padding: 0;
    list-style: none;
    flex-direction: column;
    display: flex;

    li {
      padding: $space-sm $space-md;
      margin: 0 $space-md;
      font-size: 24px;
      font-family: $din-light;
      font-weight: normal;
      letter-spacing: 3px;
      text-transform: uppercase;

      a {
        color: inherit;
        text-decoration: none;
      }

      ul#nav-about-mobile {
        padding-left: $space-sm;
        font-family: $main-font-bold;

        li {
          font-size: 8px 16px;
          line-height: 22px;
          padding: $space-md 0;
          font-family: $main-font-bold;
          border: 0;
          color: $silver;
          list-style: none;
          text-transform: uppercase;
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid rgba($gray, 0.5);
      }
    }

    &.dark {
      li {
        color: $white;
      }
    }

    &.light {
      li {
        color: $ba-dark-gray;

      }
    }
  }

  // @media(min-width: 500px) {
  //   padding-top: 150px;
  // }
  &.light{
    background-color: #f0f0f0;

  }
  &.dark, &.home{
    background-color: $charcoal;

  }
}

#mobile-nav-toggle {
  width: 37px;
  height: 30px;
  right: 10px;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  margin-top: 19px;
  margin-right: 15px;
  z-index: 999;

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: $white;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2) {
      top: 40%;
    }

    &:nth-child(3) {
      top: 40%;
    }

    &:nth-child(4) {
      top: 80%;
    }
  }

  &.open span {
    &:nth-child(1) {
      top: 20%;
      width: 0;
      left: 50%;
    }

    &:nth-child(2) {
      transform: rotate(45deg);
    }

    &:nth-child(3) {
      transform: rotate(-45deg);
    }

    &:nth-child(4) {
      top: 18px;
      width: 0;
      left: 50%;
    }
  }

  @media(min-width:768px) and (max-width: 966px) {
    margin-top: 0;
  }
  @include media-breakpoint-down(sm){
    // margin-top: 50px;
  }
}

.list-toggle {
  @include h4-caps(14px, #6C6C6C, $din-medium, 2px);
  margin-bottom: 16px;
}

.project-gallery {
  padding-right: 0;

  .gallery-item {
    margin-bottom: $space-sm;
    height: 200px;
    width: 100%;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    display: inline-block;
    position: relative;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.32);
    transition: all .5s ease-in-out;

    span {
      position: absolute;
      bottom: 27px;
      left: 36px;
      text-transform: uppercase;
      color: $white;
      font-family: $din-light;
      letter-spacing: 0.98px;
      font-size: 13px;
      line-height: $space-md;
      z-index: 100;
    }

    a {
      @include link-overlay;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.56) 100%);
      }
    }

    &.half {

      @media(min-width: 1200px) {
        height: 400px;
        width: 48%;
        height: 600px;

        &+.half {
          float: right;

          &+.half {
            float: none;
          }
        }
      }
    }

    @media(min-width: 500px) {
      height: 300px;
      margin-bottom: $space-md;
    }

    @media(min-width: 1025px) {
      height: 400px;
      margin-bottom: 24px;
    }
  }
}