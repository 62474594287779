// helpers
@mixin bg-img($img, $size: cover, $posX: center, $posY: center, $repeat: no-repeat) {
  background: url("/assets/images/#{$img}") $posX $posY $repeat;
  background-size: $size;
}

@mixin bg-full-screen {
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@mixin fill-container {
  display: block;
  height: 100%;
  width: 100%;
}

@mixin absolute-center {
  position: absolute;
  left: -40%;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

@mixin bg-cover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@mixin bg-contain {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}


@mixin keep-aspect-ratio($x, $y, $width) {
  position: relative;
  content: "";
  width: $width;
  padding-top: ($y / $x) * $width;

  img {
    display: block;
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
  }
}

@mixin no-show {
  display: none !important;
  height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
}

@mixin list-no-style {
  padding: 0;
  margin: 0;
  list-style: none;
}

@mixin list-no-style-small {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 16px;
  line-height: 24px;
}

@mixin link-style($color, $decor:none) {

  a,
  & {
    color: $color;

    &:hover,
    &:active,
    &:focus {
      text-decoration: $decor;
    }
  }
}

// elements
@mixin logo-small {
  #header-logo {
    left: $space-xl;

    img {
      background: none;
      width: 248px;

      @include media-breakpoint-down(sm) {
        width: 160px;
      }
    }
  }
}

@mixin header-light-ipad-styles {
  @include media-breakpoint-only(md) {
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: $space-md 0;

    #header-logo,
    ul#nav-desktop,
    ul#nav-about {
      position: static !important;
      margin: $space-xs 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@mixin header-mobile-styles {
  @include media-breakpoint-down(sm) {
    height: 70px;
    padding: 0;
    margin: 0;

    #header-logo {
      left: 8px;
      top: 2px;
      height: 100%;
      display: flex;
      align-items: center;

      img {
        margin: 0;
        width: 2*$space-xl;
      }
    }
  }
}

@mixin nav-about {
  position: absolute;
  padding: 0;
  right: $space-xl;
  top: 2*$space-xl + $space-lg;
  display: inline-flex;

  li {
    list-style: none;
    margin: 0 $space-md;

    &:last-of-type {
      margin-right: 0;
    }

    a {
      font-family: $main-font-bold;
      text-transform: uppercase;
      text-decoration: none;
      color: $light-gray;
      font-size: 12px;
      letter-spacing: 3px;
      padding-bottom: 3px;
      border-bottom: 2px solid transparent;
      transition: all .4s ease-in;

      &.active,
      &:hover,
      &:focus,
      &:active {
        color: $silver;
        text-decoration: none;
        border-bottom: 2px solid $silver;
      }
    }
  }
}

@mixin link-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}

@mixin list-no-style {
  list-style: none;
  padding: 0;
}

@mixin page-images {

  .image-left,
  .image-right {
    height: 325px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@mixin list-toggle {
  overflow: hidden;
  height: 0px;
  transition: all .5s ease-in-out;

  &.active {
    height: auto;
  }
}