.optional-bio-details{
  .container{
    margin-bottom: $space-xl;
  }
  .text{
    
  }
  .project-highlights{
    h4{
      @include h4-caps(12px, $ba-dark-gray, $din-medium, 1.2px);
      margin-bottom: $space-md;
    }
  }
}