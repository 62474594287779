#loading{
  background: rgba(25,25,25,.65);
  display: block;
  position: absolute;
   top: 31%;
   right: 50%;
   width: 40px;
   height: 40px;
   margin-top: -20px; /* Half the height */
   margin-left: -20px; /* Half the width */
  z-index: 100;
}

img.fixed-height {
  @include media-breakpoint-up(lg) {
    height: 325px;
  }
}

#footer-fade {
  background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); 
  bottom: 0px;
  position: fixed;
  height: 50px;
  width: 100%;
  z-index: 10;
}

#dark-fade {
  bottom: 0px;
  position: fixed;
  height: 50px;
  width: 100%;
  z-index: 1;
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
}

.error {
  border-color: $red; 
}

.bt{
  display: inline-block;
  border: 1px solid $link-blue;
  padding: 8px 8px;
  cursor: pointer;
  text-decoration: none;
  color: $link-blue;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  font-family: $din-light;
  background-color: $white;
  margin-bottom: $space-md;
  &:hover{
    text-decoration: none;
    background-color: $link-blue;
    color: $white;
    &:hover{
      text-decoration: none;
      background-color: $link-blue;
      color: $white;
    }
  }
  &.bt-fill{
    transition: background .25s ease-in-out;
    background-color: $link-blue;
    color: $white;
  }
  &.bt-fill-inverse{
    background-color: $white;
    color: $link-blue;
  }
  &.active{
    text-decoration: none;
    background-color: $link-blue;
    color: $white;
  }
}

form{
  label{
    @include label($ba-dark-gray);
    &:nth-child(5){
      @include label(#AD1F31)
    }
  }
  input{
    padding: 13px 15px;
    width: 100%;
    border-radius: 6px;
    border: 2px solid #B8C1CB;
    font-size: 20px;
    font-family: $nunito-reg;
    color: #6F6F6F;
    margin-bottom: $space-md;
    &:focus{
      border: 2px solid $link-blue;
    }
  }
  textarea{
    height: 223px;
    width: 100%;
    border-radius: 6px;
    padding: 8px;
    &:focus{
      border: 2px solid $link-blue;

    }
  }
}

.pace {
  -webkit-pointer-events: none;
  pointer-events: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: #29d;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}

.sk-cube-grid {
  width: 40px;
  height: 40px;
  margin: 100px auto;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #333;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
          animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out; 
}
.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s; }
.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
          animation-delay: 0s; }
.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1); 
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  } 
}

.down-arrow{
  position: absolute;
  top: -50px;
  left: -55px;
  font-size: 24px;
  color: $white;
  opacity: 0;
  z-index: 999;
}