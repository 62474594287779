#news {
  .post-title {
    display: inline-block;

    h3 {
      margin: $space-lg 0;
      margin-bottom: $space-xl;
      position: relative;
      @include people-title(#4B4B4B);
      font-family: $din-light;


      &:after {
        content: "";
        border-bottom: 2px solid $link-blue;
        width: 100px;
        position: absolute;
        left: 0;
        bottom: -40px;
      }
    }
  }

  #header {
    position: relative;
  }

  .sidebar {
    min-height: $space-xl;
    padding: $space-md;
    width: 120px;

  

    ul {
      padding-left: inherit;
      @include list-no-style;

      li {
        display: flex;
        align-items: center;
        margin-bottom: 6px;
        transition: all .25s ease;
        &:hover {
          background: white;
          text-decoration: none;
        }

        a {
          color: $link-blue;
          font-size: 14px;
          line-height: 16px;
          padding: 4px 8px;
          text-decoration: none;
        }

        span.qty {
          color: $ba-mid-gray;
          font-size: 14px;
          font-family: $din-light;
          background-color: rgba($light-gray,.5);
          width: 25px;
          display: inline-block;
          text-align: center;
          margin-left: 5px;
          border-radius: 4px;
          &:hover {
            background-color: $ba-gray;
            color: $ba-dark-gray;
          }
        }
      }
    }
  }

  main{
    background-color: #F0F0F0;
  }

  img {
    max-width: 100%;
  }

  article {
    padding: $space-md $space-xl;
    padding-bottom: $space-lg;
    background-color: $white;
    margin-bottom: 32px;
    position: relative;

    a {
      font-family: $main-font;
      text-decoration: underline;
      color: $silver;
      transition: all .5s ease-in-out;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.06);
      @include people-title(#4B4B4B);

      &:hover {
        text-decoration: underline;
        box-shadow: 0 15px 15px 0 rgba(0,0,0,.13);
        top: -2px;
      }
    }


    .content {
      overflow: hidden;

    }

    @media(max-width: 767px){
      padding: 50px 16px 0 16px;
    }
  }
  .text{
    @media(min-width: 767px){
      padding-right: 64px;

    }
    padding-right: 16px;
    p{
      text-align: justify;
    }
  }
  .hero-image-full{
    padding: 24px;
    float: right;
    max-height: 400px;
  }

  .news-body {
    .banner {
      min-height: 440px;
      background-size: cover;
      background-position: center;
      box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.32);
      position: relative;

      span{
        position: absolute;
        bottom: 10px;
        right: 16px;
        color: $white;
        z-index: 100;
        text-transform: uppercase;
        color: $white;
        font-family: $din-light;
        letter-spacing: 0.98px;
        font-size: 13px;
        line-height: $space-md;
      }

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.56) 200%);
      }
    }

    .content {
      position: relative;
      padding-left: $space-xl;
      padding-bottom: $space-lg;
      background-color: $white;
      box-shadow: 0 2px 20px 0 rgba(0,0,0,0.06);

      .post-year {
        top: 0px;
      }

      .post-month {
        padding-top: $space-sm;
      }

      .project-image {
        height: 440px;
        background-size: cover;
        background-position: center;
        margin-bottom: 32px;
        box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.32);
        position: relative;

        &:after {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.56) 200%);
        }

        span {
          position: absolute;
          bottom: 10px;
          left: 16px;
          color: $white;
          z-index: 100;
          text-transform: uppercase;
          color: $white;
          font-family: $din-light;
          letter-spacing: 0.98px;
          font-size: 13px;
          line-height: $space-md;
        }
      }

      & > a{
        @include p-link;
        display: block;
        padding: 8px;
        display: inline-block;
        vertical-align: middle; 
        i{
          margin-left: 8px;
          margin-top: -5px;
          vertical-align: middle;
        }
        @media(min-width: 767px){
          padding: 32px 8px;

        }
      }
      @include media-breakpoint-down(md){
        padding-left: 24px;
        padding-right: 15px;
        .post-month{
          padding-left: 32px;
        }
      }
    }
  }

  .post-year {
    padding: 4px 8px;
    background-color: #4B4B4B;
    color: $white;
    font-size: 15px;
    text-align: center;
    position: absolute;
    top: 10px;
    left: 0;
    z-index: 9;
    font-family: $din-medium;
  }

  .post-meta {
    margin-bottom: $space-md;
    position: static;

    .post-month {
      @include h4-caps(14px, $ba-dark-gray, $din-medium, 1px);
    }

    .title-wrapper {
      width: 100%;
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
      .title-wrapper {
        order: 2;
      }

      .post-title {
        max-width: 100%;
      }

      .post-month {
        text-align: left;
      }
    }
  }

  .recent-news {
    margin-top: $space-xl;

    .card-module {
      margin-bottom: 32px;
      .wrapper {
        box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
        position: relative;
        background-color: $white;
        min-height: 380px;
        transition: all .5s ease-in-out;
        top: 0;

        .img {
          min-height: 162px;
          background-size: cover;
          margin-bottom: $space-md;
        }

        .card-body {
          padding: $space-md;

          h3 {
            @include people-title(#4B4B4B);
            position: relative;
            margin-bottom: $space-xl;
            font-size: 22px;
            font-family: $din-medium;
            min-height: 75px;

            &:after {
              content: "";
              position: absolute;
              width: 100px;
              border-bottom: 2px solid $link-blue;
              bottom: -32px;
              left: 0;
            }
          }

          a {
            @include h4-caps(14px, $link-blue, $din-medium, 1px);
            display: block;
            position: absolute;
            bottom: 16px;
            left: 16px;
            text-transform: uppercase;

            &+a {
              @include link-overlay;
            }
          }
        }
        &:hover{
          box-shadow: 0 15px 15px 0 rgba(0,0,0,.13);
          top: -2px;
        }
      }
    }
  }
}