.kss-body{
  opacity: 1;
}

.kss-section__description{
  .menu-white{
    @include menu($white);
  }
  .menu-gray{
    @include menu($ba-dark-gray);
  }
  span{
    color: #E87E04;
    display: block;
    margin-bottom: 8px;
    text-transform: lowercase;
    font-weight: lighter;
    font-family: $din-light;
    font-style: italic;
  }
  .p-base{
    @include p-base;
  }
  .p-intro{
    @include font-p-intro;
  }
  .p-small{
    @include p-small;
  }
  .p-sidebar{
    @include sidebar-small-link;
  }
  .p-sm-links{
    @include p-link;
  }

  .bt{
    display: inline-block;
    border: 1px solid $link-blue;
    padding: 8px 32px;
    cursor: pointer;
    text-decoration: none;
    color: $link-blue;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    font-family: $din-light;
    background-color: $white;
    margin-bottom: $space-md;
    &:hover{
      text-decoration: none;
    }
    &.bt-fill{
      background-color: $link-blue;
      color: $white;
    }
  }
  form{
    label{
      @include label($ba-dark-gray);
      &:nth-child(5){
        @include label(#AD1F31)
      }
    }
    input{
      padding: 13px 15px;
      width: 100%;
      border-radius: 6px;
      border: 2px solid #B8C1CB;
      font-size: 20px;
      line-height: 20px;
      font-family: $nunito-reg;
      color: #6F6F6F;
      margin-bottom: $space-md;
      &:focus{
        border: 2px solid $link-blue;
      }
    }
    textarea{
      border: 2px solid #AD1F31;
      height: 223px;
      width: 100%;
      border-radius: 6px;
  
    }
  }
}


#kssref-base-typography-white{
  background-color: $ba-dark-gray;
}

// Typography White
//
// <h1 class="project-title-white">The quick brown fox jumps over the lazy dog.</h1>
// <span>H1 / People Title / White</span>
//
// <h2 class="h2-white">The quick brown fox jumps over the lazy dog.</h2>
// <span>H2 / White</span>
//
// <h4 class="h4-caps-white">The quick brown fox jumps over the lazy dog.</h4>
// <span>H4 / All Caps / White</span>
//
// <h6 class="h6-caps-white">The quick brown fox jumps over the lazy dog.</h6>
// <span>H6 / Call Caps / White</span>
//
// <h6 class="h6-call-white">The quick brown fox jumps over the lazy dog.</h6>
// <span>H6 / All Caps / White</span>
//
// <a class="menu-white">The quick brown fox jumps over the lazy dog.</a>
// <span>Menu</span>
//
// Styleguide Base.typography-white
.project-title-white{
  @include project-title($white);
}
.h2-white{
  @include h2-font($white);
}
.h4-caps-white{
  @include h4-caps (14px, $white, $din-medium, 1.4px);
}
.h6-caps-white{
  @include h6-caps (14px, $white, $din-medium, 1.4px);
}
.h6-call-white{
  @include h6-caps (13px, $white, $din-medium, 1.3px);
}


// Typography
//
// <h1 class="project-title-black">The quick brown fox jumps over the lazy dog.</h1>
// <span>H1 / People Title / Black</span>
//
// <h1 class="project-title">The quick brown fox jumps over the lazy dog.</h1>
// <span>H1 / People Title / Blue</span>
//
// <h2 class="h2-blue">The quick brown fox jumps over the lazy dog.</h2>
// <span>H2 / Blue </span>
//
// <h2 class="h2-gray">The quick brown fox jumps over the lazy dog.</h2>
// <span>H2 / Gray</span>
//
// <h3 class="h3-caps-blue">The quick brown fox jumps over the lazy dog.</h3>
// <span>H4 / All Caps / Blue</span>
//
// <h3 class="h3-gray">The quick brown fox jumps over the lazy dog.</h3>
// <span>H3 / Gray</span>
//
// <h4 class="h4-caps-gray">The quick brown fox jumps over the lazy dog.</h4>
// <span>H4 / All caps Gray</span>
//
// <h4 class="h4-sidebar-link">The quick brown fox jumps over the lazy dog.</h4>
// <span>H4 / sidebar link</span>
//
// <h5 class="h5-caps-red">The quick brown fox jumps over the lazy dog.</h5>
// <span>H5 / All Caps / Red</span>
//
// <h5 class="h5-bold">The quick brown fox jumps over the lazy dog.</h5>
// <span>H5 / Call Caps Bold / Blue</span>
//
// <h5 class="h5-caps-base">The quick brown fox jumps over the lazy dog.</h5>
// <span>H5 / Call Caps Bold / Blue</span>
//
// <h5 class="h5-caps-links">The quick brown fox jumps over the lazy dog.</h5>
// <span>H5 / Call Caps Bold / Blue</span>
//
// <p class="p-base">The quick brown fox jumps over the lazy dog.</p>
// <span>P / Base</span>
//
// <p class="p-intro">The quick brown fox jumps over the lazy dog.</p>
// <span>P / Intro</span>
//
// <p class="p-small">The quick brown fox jumps over the lazy dog.</p>
// <span>P / Small</span>
//
// <p class="p-sidebar">The quick brown fox jumps over the lazy dog.</p>
// <span>P / Sidebar / Small Link</span>
//
// <p class="p-sm-links">The quick brown fox jumps over the lazy dog.</p>
// <span>P / Small Link</span>
//
// <a class="menu-gray">The quick brown fox jumps over the lazy dog.</a>
// <span>Menu Gray</span>
//
// Styleguide Base.typography
.menu-gray{
  @include menu($ba-dark-gray);
}
.project-title{
  @include project-title($link-blue);
}

.project-title-black{
  @include project-title($black);
}

.h2-blue{
  @include h2-font($link-blue);
}

.h2-gray{
  @include h2-font($ba-dark-gray);
}

.h3-caps-blue{
  @include h3-caps-blue();
}

.h3-gray{
  @include h3-font($ba-dark-gray, $din-light);
}

.h4-caps-gray{
  @include h4-caps (14px, $ba-dark-gray, $din-medium, 1.4px);
}

.h4-sidebar-link{
  @include h4-sidebar-link();
}

.h5-caps-red{
  @include h5-caps-red;
}

.h5-bold{
  @include h5-bold;
}

.h5-caps-base{
  @include h5-all-caps-base;
}
.h5-caps-links{
  @include h5-caps-links;
}



// Highlight Colors
//
// Colors we use for higlighting states.
//
// $white - #ffffff; white
// $black - #000000; black
// $charcoal - #222; charcoal
// $light-charcoal - #555558; light charcoal
// $theme-dark - #8a9199; theme darl
// $dark-gray - #4d4d4f; dark gray
// $medium-gray - #a0a0a0; medium gray
// $light-gray - #bdbdbd; light gray
// $lightest-gray - #f7f7f7; lightest-gray
// $fog - #efefef; fog
// $silver - #9ba5ae; sliver
// $dark-silver - #566675; dark silver
// $smoke - #ccc; smoke
// $light-purple - #6279b7; light purple
// $red - #d9534f; red
// $link-blue -#1B5191; link blue
// $background-gray - #f0f0f0; background gray
// $error - #ad1f31; error
// $ba-dark-gray - #4b4b4b; ba dark gray
// $ba-mid-gray - #6f6f6f; ba mid gray
// $ba-gray - #a4a4a4; ba gray
// $light-blue - #2D8EFF; light blue
//
// Styleguide Base.colors

// Buttons
//
// Butler Buttons
//
// <a class="bt">Email</a>
// <a class="bt bt-fill">Email</a>
//
// .bt-fill - Fills backgrounnd and changes text color to white.<br>
//
// Styleguide Components.buttons

// UI
//
// Inputs and Labels
//
// <form>
// <label>Email</label>
// <input placeholder="email">
// <label>Name</label>
// <input placeholder="name">
// <label>Message</label>
// <textarea></textarea>
// </form>
//
// Styleguide Components.ui

