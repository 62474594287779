#image{
  background-color:#4b4b4b;
  header#header{
    position: relative;
  }
  .container.image{
    .row{
      @media(max-width:992px){
        flex-direction: column-reverse;
      }
      .sidebar{
        .bt{
          padding: 8px 16px;
          text-transform: capitalize;
          @media(min-width: 992px){
            margin-left: -100px; 
            margin-top: 50px; 
          }
          svg,path,polyline,g{
            position: relative;
            z-index: 99;
            fill: $link-blue;
            stroke: $white;
          }
        }
      }
      img{
        width: 100%;
      }
    }
  }
}