/*new project*/
#projectNew,
#projects {
  font-family: $nunito-reg;
  color: $medium-gray;

  header#header {
    // @include logo-small;
    @include header-mobile-styles;
  }

  footer{
    margin-top: 0;
  }

  main{
    overflow: hidden;
  }

  #description {
    display: block;
    padding: 0 20px 90px 30px;
    color: $medium-gray;
    line-height: 66px;
    z-index: 2;
    position: relative;
    background-color: #F0F0F0;
    position: relative;
    @media(max-width: 767px){
      padding: 0 0 32px 0;
    }

    .body-matrix {
      background: linear-gradient(0deg, rgba(240,240,240,0) 10%, #fafafa 100%);
      padding: 32px 0;
    }

    .project-details {
      hr {
        background-color: $ba-gray;
      }

      color: $ba-gray;

      h4 {
        @include h4-caps(14px, $ba-dark-gray, $din-medium, 1px);
        margin-bottom: $space-sm;
        margin-top: $space-lg;
      }

      h3 {
        font-size: 18px;
        color: $dark-gray;

        &.project-size{
          font-family: $nunito-reg;
          font-weight: 800;
        }

        &.completion-date{
          font-family: $nunito-reg;
          font-weight: 800;
        }

        span{
          font-family: $din-bold;
        }
      }

      p {
        font-family: $din-light;
        position: relative;

        a{
          @include p-small();
          color: $link-blue;
          border-bottom: none;
          text-decoration: underline;
          font-family: $din-light;
          font-size: 16px;
        }

        &.link{
          text-decoration: underline;
          margin-bottom: 15px;
          line-height: 22px;
          a{
            font-size: 16px;
            line-height: 22px;
            border-bottom: none;
            padding-bottom: 2px;
            font-family: $din-light;
          }
          svg{
            stroke: $link-blue;
            width: 16px;
            height: auto;
            vertical-align: middle;
            position: absolute;
            left: 0;
            top: 5px;
          }
        }
      }
      .designers{
        p{
          color: $ba-dark-gray;
          font-size: 16px;
          line-height: 22px;
        }
      }
      .awards{
        img{
          width: 80%;
          padding: 16px 0;
          filter: grayscale(100%);
        }
      }
    }

    p {
      margin-bottom: 16px;
      border-bottom: none;
      

      &#details {
        text-align: normal;
      }

      a {
        @include p-small();
        color: $link-blue;
        border-bottom: none;
        text-decoration: underline;
        font-family: $din-light;
      }
    }

    a {
      text-decoration: none;
    }

    #details {
      display: block;
      margin-top: 10px;
    }

    .charcoal-fade {
      height: 50px;
      position: fixed;
      top: 550px;
      left: 8%;
      width: 25%;
      background: linear-gradient(to bottom, rgba($charcoal, 0) 59%, rgba($charcoal, 1) 100%);
    }

    .title-bar {
      h1 {
        margin-bottom: 0;
        @include project-title(#FAFAFA);
        font-size: 24px;
      }

      background-color: rgba(0, 0, 0, 0.37);
      z-index: 999;
      position: absolute;
      top:-55px;
      left: 15px;
      padding: 5px;
      padding-bottom: 15px;
      transition: all .5s ease-in-out;
      cursor: pointer;

      &.active {
        top: -90px;
        background-color: #FAFAFA;

        h1 {
          color: $ba-dark-gray;
          margin: 15px 0;
          font-size: 28px;
        }

        .img-location {
          div {
            background-color: #C6C6C6;
            box-shadow: 0 0 20px 0 #fff;

            &.active {
              background-color: #1B5191;
            }
          }
        }
      }
      &.short{
        @media(max-width:767px){
          top: -80px;
          &.active{
            top: -90px;
          }
        }
      }

      .img-location {
        display: flex;
        padding-top: 10px;
        width: 100%;

        div {
          height: 4px;
          background-color: #C6C6C6;
          width: 55px;
          cursor: pointer;

          &.active {
            background-color: white;
            box-shadow: 0 0 20px 0 #fff;
          }
        }
      }
      @media(min-width: 767px){
        padding: 10px 60px;
        padding-bottom: 40px;
      }
    }
  }

  .related-projects {
    margin-top: 64px;

    &>h4 {
      @include h4-caps(12px, $dark-gray, $din-medium, 1.2px);
    }

    .related-projects__carousel {
      height: 350px;
      width: 100%;

      .project {
        height: 100%;
        width: 440px;
        background-size: cover;
        position: relative;
        transition: all .5s ease-in-out;
        &:not(:last-child){
          .gradient{
            transition: all .5s ease-in-out;
            content:"";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.56) 100%);
            box-shadow: 0 5px 15px 0 rgba(0,0,0,0.32);
            opacity: 1;
          }
        }

        a{
          color: $white;
          @include link-overlay;
        }
      

        h4 {
          position: absolute;
          bottom: 10px;
          left: 0px;
          right: 0;
          text-align: center;
          z-index: 10;
          @include h4-caps(14px, $white, $din-medium, 1px);
          @media(min-width: 767px){
            bottom: 16px;
            left: 13px;
            text-align: left;
            width: auto;
          }
        }

        &:hover{
          &:not(:last-child) {
            .gradient {
              opacity: 0;
            }
            transform: scale(.9);
          }
        }
        &.blank{
          @media(max-width: 767px){
            display: none;
          }
        }
      }

      .flickity-button.next{
        right: 500px;
        height: 30%;
        top: 0;
        bottom: 0;
        margin: auto;
        background-color: rgba(0,0,0,.5);
      }
      .flickity-button.previous{
        height: 30%;
        top: 0;
        bottom: 0;
        margin: auto;
        background-color: rgba(0,0,0,.5);
      }

      .flickity-button{
        transition: all .5s ease-in-out;
        &:disabled{
          opacity: 0;
        }
        @media(max-width: 767px){
          display: none;
        }
      }

      @media(min-width: 992px) {
        width: 150%;
      }
    }

    @media(min-width: 992px) {
      position: relative;
      width: 100%
    }
  }

  a {
    &.button {
      position: absolute;
      display: block !important;
      top: 40px;
      z-index: 3;
      transition: all .5s ease-in-out;

      img {
        max-height: 58px !important;
        height: 58px !important;
      }
    }

    &.active {
      display: none !important;
    }

    &#prev-photo {
      position: absolute;
      left: 0;
      top: 30%;

      &.active {
        transform: translateX(-100%);
      }
    }

    &#next-photo {
      display: none;
      position: absolute;
      right: 0;
      top: 30%;

      &.active {
        transform: translateX(100%);
      }
    }
  }

  #slideshow {
    overflow: hidden;
    width: 100%;
    z-index: 1;
    height: 100%;

    ul {
      width: 100000px;
      margin: 0 auto;
      padding: 0;
      position: relative;
      overflow: visible;
    }

    li {
      position: relative;
      display: inline;
      float: left;
      left: 0;
      margin: 0 auto;
      overflow: hidden;

      img {
        filter: alpha(opacity=20) !important;
        opacity: 0.2;
        height: 100%;
        height: 100vh !important;
        overflow: hidden;
        margin: 0;
        padding: 0;
        position: relative;
        zoom: 1;

        &.ver {
          position: relative;
          display: inline;
          margin: 0 auto !important;
          padding: 0 auto;
          text-align: center;
          vertical-align: text-bottom;
          filter: alpha(opacity=100);
          opacity: 1;
          overflow: hidden;
          max-height: 100%;
          -moz-box-shadow: 0 0 20px #222;
          -webkit-box-shadow: 0 0 20px #222;
          box-shadow: 0 0 20px #222;
        }
      }
    }

    &.active {
      cursor: none;
    }
  }

  #footer {
    display: none;
    position: fixed;
    bottom: 0;
    margin: 0;
    padding: 0;
    height: 50px;
    width: 100%;
    background: $black url(../static/footer-texture.png) bottom center repeat-x;
    overflow: hidden;
    z-index: 10;
  }

  #footer-inner {
    position: relative;
    padding: 0;
    width: 80%;
    height: 50px;
    margin: 0 auto;
    background: transparent url(../static/footer-glint.png) bottom center no-repeat;
  }

  #project-footer {
    display: block;
    float: left;
    width: 80%;
    font-family: $main-font;
    text-transform: uppercase;
    height: 50px;
    line-height: 50px;
    font-size: 24px;
    color: $white;
    margin-left: -26px;

    h1 {
      font-family: $main-font-condensed;
      display: inline;
      line-height: 50px;
      font-size: 20px;
      font-weight: 100;
      margin: 0;
      padding: 0;
      float: left;

      a {
        color: $white;
        text-decoration: none;

        &:hover {
          color: $white;
        }
      }
    }

    #nav-buttons {
      display: block;
      float: left;
      width: 240px;
      margin-left: 20px;
      z-index: 3;

      a {
        opacity: 0.6;
        transition: all .2s ease-in;

        &.next-project {
          float: none;
          width: 110px;
          padding-left: 0px;
        }

        &.prev-project {
          float: none;
          width: 110px;
        }

        &:hover {
          opacity: 1;
        }

        & img {
          width: 110px;
        }
      }
    }
  }

  .image-modal{
    background-color: rgba(0,0,0,.8);
    padding: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: fixed;
    left:0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 999;
    opacity: 0;
    z-index: -1;
    transform: scale(0);
    transition: all .5s ease-in-out;
    #close{
      position: absolute;
      top: 64px;
      right: 64px;
      @media(max-width: 1024px) {
        top: 32px;
        right: 32px;
        transform: scale(.75);
      }
      @media(max-width: 769px) {
        top: 16px;
        right: 16px;
        transform: scale(.5);
      }
      color: $dark-gray;
      transform: scale(1);
      cursor: pointer;
      z-index: 999;
      svg{
        width: 40px;
        height: 40px;
      }
    }

    img{
      height: 90%;
      position: relative;
      z-index: 99;
    }


    &.active{
      opacity: 1;
      z-index: 999;
      transform: scale(1);
    }
  }
}
.carousel-cell {
  width: 100%; /* full width */
  height: 200px;
  background: #222;
  /* center images in cells with flexbox */
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel.is-fullscreen .carousel-cell {
  height: 100%;
}

.carousel-cell img {
  display: block;
  max-height: 100%;
}

.carousel img {
  display: block;
  height: 100vh;
}

.carousel.is-fullscreen .carousel-cell {
  height: 100%;
}

@media screen and (min-width: 768px) {
  .carousel img {
    height: 100vh;
  }
}

.carousel-image {
  position: relative;
  opacity: .1;

  &.is-selected {
    opacity: 1;
  }
}

.carousel-related {
  width: 1300px;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 999;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.32);
    background: linear-gradient(0deg, rgba(130,148,195,0) -40%, rgba($link-blue,.8) 100%);
  }

  .wrapper {
    width: 55%;
    height: 40%;
    position: relative;
    margin-left: 64px;
    margin-top: -2*$space-xl;
    z-index: 999;

    h2 {
      @include h4-caps(25px, $white, $din-medium, 1.4px);
      position: relative;
      margin: 8px;
      z-index: 999;
      margin-bottom: 16px;
    }

    .autoplay {
      font-size: 16px;
      color: $white;
      font-family: $din-light;
      text-transform: uppercase;
      letter-spacing: 1px;
      border-radius: 5px;
      padding: 8px;
      position: absolute;
      right: 8px;
      top: 0;

      span {
        color: $white;
        background-color: rgba($link-blue, 0.53);
        border-radius: 4px;
        padding: 3px;
        display: inline-block;
        margin-left: 8px;
        opacity: 1;
        text-align: center;
        width: 32px;
      }
    }

    .related-item {
      position: relative;
      width: 46%;
      display: inline-block;
      height: 240px;
      margin: 8px;
      background-size: cover;
      background-repeat: no-repeat;
      box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.32);
      z-index: 999;

      @media(min-width: 992px) {
        &:nth-child(3) {
          float: right;
        }

        &:last-child {
          float: right;
        }
      }

      h4 {
        position: absolute;
        bottom: 16px;
        left: 13px;
        color: $white;
        @include h4-caps(13px, $white, $din-light, .98px);
      }

      a {
        @include link-overlay;
      }
    }
  }
}

.flickity-prev-next-button {
  border-radius: initial;
  opacity: 1;
  transition: all .5s ease-in-out;
  transform: none;
  background-color: transparent;
  border-radius: 4px;
  padding: 8px;
  display: block;
  height: auto;
  width: 45px;

  &:hover{
    background-color: rgba(0,0,0,.5);
  }
  img{
    width: 100%;
    height: 50px;
  }

  &.next.active {
    opacity: 0;
    transform: translateX(100%)
  }

  &.previous.active {
    opacity: 0;
    transform: translateX(-100%)
  }
}