
@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=dcaf83f8-a9d0-49a8-a47c-6e05d3a2f666");
@font-face{
    font-family:"DIN Next LT W01 Light";
    src:url("../fonts/fb0dd5e6-cf57-43be-8f3f-2880d5389694.eot?#iefix");
    src:url("../fonts/fb0dd5e6-cf57-43be-8f3f-2880d5389694.eot?#iefix") format("eot"),url("../fonts/14aba99e-8c97-4daa-a8df-67920a051402.woff2") format("woff2"),url("../fonts/96fd6d6f-fad6-4dd9-ab70-29547a56cacd.woff") format("woff");
}
@font-face{
    font-family:"DIN Next LT W01 Medium";
    src:url("../fonts/c6dbdd54-50f5-472a-8b74-0e98ef77e33e.eot?#iefix");
    src:url("../fonts/c6dbdd54-50f5-472a-8b74-0e98ef77e33e.eot?#iefix") format("eot"),url("../fonts/afabdfcf-432c-49b4-a496-0e5051e87fa9.woff2") format("woff2"),url("../fonts/611ea865-db7e-4c05-94b8-bd1675c90576.woff") format("woff");
}
@font-face{
    font-family:"DIN Next LT W01 Bold";
    src:url("../fonts/fa5ed46b-792d-4f47-894e-fa587cf4e1cf.eot?#iefix");
    src:url("../fonts/fa5ed46b-792d-4f47-894e-fa587cf4e1cf.eot?#iefix") format("eot"),url("../fonts/03008527-67bd-478e-98e3-3b56dd9a6520.woff2") format("woff2"),url("../fonts/557f817d-9c25-4a23-baaf-a3cf84a7cd7c.woff") format("woff");
}

$main-font: "Nunito Sans", Arial, Helvetica, sans-serif;
$main-font-condensed: "Nunito Sans", Arial, Helvetica, sans-serif;
$main-font-bold: "Nunito Sans", Arial, Helvetica, sans-serif;
$main-font-bold-2: "Nunito Sans", Arial, Helvetica, sans-serif;
$nunito-reg: "Nunito Sans", Arial, Helvetica, sans-serif;
$din-light: 'DIN Next LT W01 Light', Arial, Helvetica, sans-serif;
$din-medium: 'DIN Next LT W01 Medium', Arial, Helvetica, sans-serif;
$din-bold: 'DIN Next LT W01 Bold', Arial, Helvetica, sans-serif;

p {
  text-align: justify;
}