#contact {
  #header {
    background: transparent;
  }

  .banner {
    min-height: 60vw;
    background-size: cover;

    @media(min-width: 767px) {
      min-height: 40vw;

    }
  }

  .details {
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.06);
    background-color: #FAFAFA;
    margin-bottom: $space-xl;
    padding: $space-xl;
    padding-bottom: 128px;

    @media(min-width:767px) {
      margin-top: -$space-xl;

    }

    h1 {
      @include people-title($dark-gray);
      position: relative;
      font-family: $din-light;
    }

    p {
      font-weight: 300;
    }

    ul {
      
      &.contact-info {
        list-style-type: none;

        li {
          @include p-base;
          font-weight: 300;
          position: relative;
          font-size: 20px;
          line-height: 32px;

          i {
            position: absolute;
            left: -32px;
            top: 5px;
            color: $link-blue;
          }

          a {
            color: #6f6f6f;
          }
        }
      }
    }

    #parking-options {
      margin: 0 !important;

      font-size: 16px;

      p{
        font-weight: normal;
        font-size: 16px;
        margin: 0;
      }
      ul{
        padding: 0;
        li{
          list-style-position: inside;
          position: relative;
        }
      }
    }

    #directions {
      img {
        width: 100%;
      }
    }

    .wrapper {
      position: relative;
      margin-bottom: $space-lg;

      .form-feed-back {
        height: 600px;
        width: 580px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        background-color: $white;

        h3 {
          display: none;
          text-align: center;
        }
      }

      form {
        h5 {
          text-transform: capitalize;
          font-size: 24px;
        }

        h3 {
          @include h3-font($dark-gray, $din-bold);
        }

        padding-top: 32px;
        position: relative;
        z-index: 1000;

        p {
          position: relative;

          i {
            position: absolute;
            left: 32px;
            top: 45px;
            transform: scale(1.5);

            @media(max-width: 340px) {
              top: 95px;
              display: none;
            }
          }

          button {
            @media(min-width: 521px) {
              position: absolute;
              right: 32px;
              top: 35px;
            }
          }
        }

        input {
          &[type="email"]{
            padding-left: 64px;
          }
          &[type="submit"] {
            width: 200px;
            border-radius: initial;
            margin-top: $space-md;
          }
        }
      }

      .socials {
        margin: $space-lg 0;

        .links {
          display: flex;
          justify-content: center;
          align-items: center;

          a {
            color: $light-gray;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 16px 0;
            border-radius: 4px;
            margin-bottom: 16px;
            text-decoration: none;
            width: 100%;
            transition: all .5s ease-in-out;

            &:hover {
              color: $link-blue;

              label,
              i {
                color: $link-blue;
              }

              box-shadow: 0 5px 8px 0 rgba(0,0,0,0.05);
            }

            label {
              margin: auto;
              margin-top: $space-md;
              transition: all .5s ease-in-out;
            }

            i {
              text-align: center;
              transform: scale(1.2);
              transition: all .5s ease-in-out;

              &.hide {
                display: none;
              }
            }

            &:hover {
              color: $ba-gray;
            }
          }
        }
      }
    }

    .staff-directory{
      padding-top: 32px;
      h5{
        margin-top: 16px;
      }
      ul{
        // overflow-y: scroll;
        // max-height: 340px;
        display: flex;
        flex-wrap: wrap;
        @include list-no-style;
        li{
          display: flex;
          width: 49%;
          justify-content: space-between;
          // @media(min-width: 767px){
            padding: 0px 16px 4px 0px;
          // }
          a{
            text-align: right;
          }
        }

        @media(max-width:768px){
          li{
            width: 100%;
          }
        }
      }
    }
  }

  .sidebar {
    position: relative;

    @media(min-width: 767px) {
      margin-top: 32px;
    }
  }

  @include page-images;

  #careers{
    a{
      display: block;
      margin-bottom: 16px;
    }
  }
}