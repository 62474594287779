footer {
  background-color: $ba-dark-gray;
  padding-top: $space-xl;
  margin-top: $space-lg;
  padding-bottom: $space-md;

  #header-logo {
    position: relative;
    display: flex;
    align-items: center;
    z-index: 3;
    padding-top: 10px;
    margin-bottom: 15px;

    @include media-breakpoint-up(lg) {
      img {
        width: 105px;
      }
    }

    @include media-breakpoint-down(lg) {
      img {
        width: 105px;
      }
    }
  }

  hr {
    background-color: #6f6f6f;
    margin: 0 16px;
    display: block;

    @media(min-width: 500px){
      max-width: 1200px;
      margin: auto;
      margin-top: $space-md;
    }
  }

  ul {
    @include list-no-style-small
  }

  .left {
    .contact {
      p {
        @include p-small-footer($line-height);
        margin-top: 10px;
      }
      a {
        @include p-small-footer(30px);
        text-decoration: none;
        transition: color .25s ease-in-out;
        &:hover {
          color: $white;
        }
      }
      margin-bottom: 20px;
    }
  }

  .right {
    a {
      @include h5-caps-links ();
      text-decoration: none;
      transition: color .25s ease-in-out;
      text-transform: uppercase;
      font-family: $din-medium;
      color:rgba($light-gray, 0.6);
      &:hover {
        color: $white;
      }
    }

    .categories{
      margin-bottom: 20px;
    }

    .social {
      margin-top: 10px;
      .content {
        .links {
          display: flex;
          justify-content: space-evenly;

          a {
            margin-bottom: $space-sm;
          }

          @media(min-width: 769px) {
            flex-direction: column;
            justify-content: center;
          }
        }
      }
    }
  }

  &.dark {
    background-color: $dark-gray;

    .social {
      a {
        opacity: 0.4;
        color: $smoke;
        transition: all .2s ease-in;

        &:hover {
          color: $white;
        }
      }
    }
  }

  &.light {
    background-color: $white;

    .social {
      a {
        color: $fog;
        transition: all .2s ease-in;

        &:hover {
          color: $light-gray;
        }
      }
    }
  }

  .copyright {
    padding-top: 16px;
    @include p-small-footer(18px);
    text-align: right;
    @media(max-width: 767px){
      font-size: 11px;
    }
  }
}