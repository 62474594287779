/* IE10+ */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

  // prevent inline items from overflowing parent
  h1, h2, h3, h4, h5, h6, p, a, table {
    max-width: 100%;
  }

  // bootstrap overrides and fixes
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      @for $size from 1 through $grid-columns {
        .row {
          .col-#{$breakpoint}-#{$size} {
            max-width: percentage($size / $grid-columns);
          }
        }
      }
    }
  }

}

// edge
@supports (-ms-accelerator:true) {

}
