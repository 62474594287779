#galleryIndex {
  background-color: $background-gray;
  header#header{
    @include header-mobile-styles;
    position: relative;
  }
  #portfolio{
    .container{
      .list-toggle{
        @media(min-width: 768px){
          display: none;
        }
      }
      .project-list{
        hr{
          box-shadow: -1px -1px 0px rgba(255, 255, 255, 0.1), 1px 1px 0px rgba(0, 0, 0, 0.3);
          width: 201px;
          display: inline-block;
          margin-right: auto;
          margin-top: 32.5px;
          margin-bottom: 25px;
          @media(min-width:768px){
            margin-top: 65px;
            margin-bottom: 50px;
          }
        }
        h4{
          @include h4-caps(14px, $ba-dark-gray, $din-medium, 1px);
        }

        .icons{
          display: flex;
          flex-wrap: wrap;
          .icon{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-evenly;
            align-items: center;
            text-align: center;
            color: rgba(0,0,0,0.5); 
            transition: all .5s ease-in-out;
            cursor: pointer;
            height: 40px;
            width: 44%;
            margin: $space-sm 8px;
            font-size: 12px;
            font-family: $din-medium;
            text-transform: uppercase;
            line-height: 14px;
            letter-spacing: 1.2px;
            position: relative;
            a{
              @include link-overlay;
            }
            span{
              display: flex;
              align-items: center;
              padding-right: 8px;
              width: 68px;
            }
            svg{
              fill: #7f7f7f !important;
              height: 25px;
              transition: all .5s ease-in-out;
              path{
                fill: #7f7f7f !important;
              }
            }
            &:hover{
              box-shadow: 0 5px $space-sm 0 rgba(0,0,0,0.05);
              background-color: #FAFAFA;
              color: $link-blue !important;
              svg{
                fill: $link-blue !important;
                color: $link-blue !important;

                path{
                  fill: $link-blue !important;
                  color: $link-blue !important;

                }
              }
            }
            &.active{
              box-shadow: 0 5px $space-sm 0 rgba(0,0,0,0.05);
              background-color: #FAFAFA;
              color: $link-blue;
              svg{
                fill: $link-blue !important;
                path{
                  fill: $link-blue !important;
                }
              }
            }
            @media(min-width: 767px){
              width: 33%;
              flex-direction: column;
              height: 70px;
              span{
                position: relative;
                display: block;
                padding: 0;
                width: 100%;
              }
            }
            @media(min-width: 1200px){
              width: 26%;
            }
          }
        }
        ul{
          @include list-no-style;
          li{
            a{
              font-size: 14px;
              color: $link-blue;
              letter-spacing: 2px;
              text-transform: uppercase;
              font-family: $din-light;
              font-style: normal;
              line-height: 18px;
              display: block;
              transition: all .25s ease;
              padding: 8px 22px;
              &:hover {
                background: white;
                text-decoration: none;
              }
            }
          }
        }
        @media(min-width:992px){
          max-width: 416px;
        }
      }

      @media(max-width:992px){
        max-width: 100%;
      }
      @media(min-width: 1024px){
        margin-left: 55px;
        max-width: 100%;
      }
    }
    
  }
}