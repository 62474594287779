#team,
#jobPostings {
  .inline-image{
    margin-left: 32px;
    margin-bottom: 8px;
    width: 55%;
    float: right;
    img{
      margin-bottom: 0 !important;
    }
    @media(max-width: 767px){
      width: 100%;
      float: initial;
      margin:0;
      margin-bottom: 16px;
    }
  }
  header#header {
    // position: relative;

    ul#nav-about {
      @include nav-about;
    }

    @media(max-width: 767px) {
      position: relative;
    }
  }

  img {
    max-width: 100%;
  }

  .image {
    width: 100%;
    height: 250px;
    @include bg-cover;
  }

  .image-full {
    width: 100%;
    min-height: 800px;
    @include bg-cover;
  }

  .team-contact {
    width: 100%;
    height: 250px;
    color: $white;
    background-color: $theme-dark;
    padding: $space-md $space-lg;

    h3 {
      text-transform: uppercase;
      font-size: 24px;
      font-family: $main-font-condensed;
      border-bottom: 1px solid rgba($white, 0.2);
      margin-bottom: $space-md;
      padding-bottom: $space-sm;
    }

    p {
      font-size: $space-md;
      line-height: 24px;
      margin: $space-stack-sm;

      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }

  .phone {
    padding-bottom: $space-xl;
  }

  .button {
    font-size: 14px;
    text-transform: uppercase;
    border: 1px solid rgba($white, 0.2);
    padding: 3px 10px 3px 10px;
    transition: background .25s ease-in-out;

    &:hover {
      background: rgba($charcoal, .5);
    }
  }

  .projects {
    margin-top: $space-lg;
    color: $theme-dark;

    h3 {
      text-transform: uppercase;
      margin-bottom: $space-md;
    }

    ul {
      @include list-no-style;

      li {
        text-transform: capitalize;
        @include link-style($theme-dark);

      }
    }
  }

  .exec-contact {
    width: 100%;
  }

  .main-content {
    margin: $space-lg 0;
  }

  .team-content {
    width: 100%;
    margin: $space-lg 0;

    p {
      margin-top: $space-md;
    }

    .member-title {
      margin-top: $space-lg;
      margin-bottom: 0;
    }

    .dots-rule {
      border-bottom: 1px dotted $light-gray;
      padding-bottom: 12px;
    }
  }

  .team-roles {
    margin: $space-lg 0;
    width: 100%;

    h4 {
      margin: $space-sm 0;
      text-transform: uppercase;
      font-size: $space-md;
      letter-spacing: 0.1em;
    }

    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: $space-lg;

      li {
        a {
          font-size: $space-md;
          color: $theme-dark;
          text-decoration: none;
          cursor: pointer;
          transition: all .2s ease-in;

          &:hover {
            color: $dark-silver;
          }
        }
      }
    }
  }

  main {
    background-color: #F0F0F0;

    .banner {

      background-color: $link-blue;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(180deg,#8a9799 50%,#738285 98%);
      }

      @media(min-width: 767px) {
        height: 520px;
      }
    }

    .content {
      & > .container{
        @media(min-width:1200px){
          margin-left: 95px;
        }
      }
      .details {
        padding: 15px;
        background: linear-gradient(0deg, rgba(240, 240, 240, 1) 10%, #fafafa 100%);

        ul {
          li {
            @include p-base()
          }
        }

        @media(min-width: 767px) {
          margin-top: -5*$space-xl;

        }

        .row {
          margin-bottom: 64px;
          position: relative;

          .team-arrow {
            position: absolute;
            top: 25%;

            img {
              width: 30px;
              height: 50px;
            }

            &#next {
              right: -90px;
            }

            &#prev {
              left: -90px
            }
          }

          &:first-child {
            margin-bottom: 32px;
          }

          .img {
            box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.17);
            width: 100%;
            height: 300px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            margin-bottom: $space-lg
          }

          .content-intro {
            h1 {
              @include people-title($link-blue);
            }

            h4 {
              @include h4-caps(12px, $ba-dark-gray, $din-medium, 4px);
              border-bottom: 1px solid #D8D8D8;
              padding-bottom: $space-sm;
              margin-bottom: $space-md;
            }

            h5 {
              color: $ba-gray;
            }

            p {
              @include font-p-intro();

            }
          }

          .content-bio{
            div{
              p{
                margin: 0;
              }
            }
            p{
              text-align: justify;
            }
          }

          .content-sidebar {
            h3 {
              font-family: $din-bold;
              letter-spacing: -1px;
              line-height: 36px;
              color: #4B4B4B;
            }

            h4 {
              @include h4-caps(12px, $ba-dark-gray, $din-medium, 1.2px);
              margin-top: $space-lg;
            }

            .bt {
              width: 100%;
            }

            ul.awards {
              @include list-no-style;

              li {
                text-decoration: underline;
                p {
                  @include p-link;
                  font-size: 18px;
                  margin: 0;
                  font-family: $din-light;
                  line-height: 26px;
                  text-decoration: none;
        
                  .link {
                    @include sidebar-small-link();
                    font-family: $din-light;
                    color: $link-blue;
                    text-decoration: underline;
                    line-height: 22px;
                    border: none;
        
                    &.active {
                      color: $ba-dark-gray;
                      border-bottom: none;
                      text-decoration: none;
                    }
                  }
                }
              }
            }

            .socials {
              @include list-no-style;
              display: flex;

              li {
                margin-right: $space-md;

                a {
                  cursor: pointer;

                  i {
                    cursor: pointer;

                  }
                }

              }
            }
          }
        }

        @media(min-width: 992px) {
          padding: 49px;

          .row {
            .img {
              height: 242px;
            }
          }
        }

        &.single {
          &.details{
            p {
              text-align: justify;
            }

            img{
              margin-bottom: $space-lg;
            }
          }
          .row {
            margin-bottom: 0;

            &:before {
              content: none;
            }

            .bt {
              @media(min-width: 768px) {
                margin-top: $space-lg;
                margin-bottom: $space-lg;
                width: 48%;

                &+.bt {
                  float: right;
                }
              }
            }

            p {
              font-weight: 300;
              margin-bottom: $space-lg;
            }
          }
        }
      }

      .sidebar {
        hr {
          background-color: #A4A4A4;
          opacity: 0.25;
        }

        margin-top: $space-lg;
        margin-bottom: $space-lg;
        padding-right: 0;

        h4 {
          @include h4-caps(14px, $ba-dark-gray, $din-medium, 1px);
          margin-top: $space-lg;
        }

        .bt {
          padding: 8px;
          width: 100%;
        }

        p {
          @include p-link;
          font-size: 18px;
          margin: 0;
          font-family: $din-light;
          line-height: 26px;

          .link {
            @include sidebar-small-link();
            font-family: $din-light;
            color: $link-blue;
            transition: all .25s ease;
            padding: 4px 8px;
            &:hover {
              background: white;
              text-decoration: none;
            }
            &.active {
              background: rgba($link-blue,0.15);
              font-family: $din-light;
              border-bottom: none;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}