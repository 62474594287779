//colors
$shark: #27272a;
$mine-shaft: #303032;
$athens-gray: #eeeef4;

.jspContainer {
	overflow: hidden;
	position: relative;
	height: 100% !important;
}
.jspPane {
	position: absolute;
}
.jspVerticalBar {
	position: absolute;
	top: 0;
	right: 0;
	width: 7px;
	height: 100%;
	background: transparent;
	* {
		margin: 0;
		padding: 0;
	}
	.jspArrow {
		height: 16px;
		&:focus {
			outline: none;
		}
	}
}
.jspHorizontalBar {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 0;
	background: transparent;
	* {
		margin: 0;
		padding: 0;
	}
	.jspCap {
		float: left;
	}
	.jspTrack {
		float: left;
		height: 100%;
	}
	.jspDrag {
		float: left;
		height: 100%;
	}
	.jspArrow {
		width: 16px;
		float: left;
		height: 100%;
	}
}
.jspCap {
	display: none;
}
.jspTrack {
	background: $shark;
	position: relative;
	height: 100% !important;
}
.jspDrag {
	background: $mine-shaft;
	position: relative;
	top: 0;
	left: 0;
	cursor: s-resize;
}
.jspArrow {
	background: $mine-shaft;
	text-indent: -20000px;
	display: block;
	cursor: s-resize;
	&.jspDisabled {
		cursor: default;
		background: $mine-shaft;
	}
}
.jspCorner {
	background: $athens-gray;
	float: left;
	height: 100%;
}
ul.portlist {
	.jspContainer {
		overflow: hidden;
		position: relative;
		z-index: 5;
		height: 100% !important;
	}
	.jspTrack {
		background: transparent;
		position: relative;
		height: 100% !important;
	}
	.jspCorner {
		background: transparent;
		position: relative;
		height: 100% !important;
	}
	.jspVerticalBar {
		position: absolute;
		top: 0;
		right: 0;
		width: 7px;
		height: 100% !important;
	}
	.jspDrag {
		background: $white;
		position: relative;
		top: 0;
		left: 0;
		cursor: s-resize;
	}
}
* html .jspCorner {
	margin: 0 -3px 0 0;
}
