.content-blocks {
  & >.container{
    padding: 0 60px !important;
  }
  .copyWithIcons {
    padding: 64px 0;

    h3 {
      margin-bottom: 100px;
      position: relative;
      @include font-p-intro;

      &:after {
        content: "";
        position: absolute;
        bottom: -64px;
        left: 0;
        width: 200px;
        height: 1px;
        border-bottom: 1px solid #979797;
        margin-bottom: 32px;
      }
    }

    .icons {
      @media(min-width: 992px) {
        position: absolute;
        top: 0;
        left: -40%;
        width: 300px;
        display: flex;
      }

      flex-wrap: wrap;
      justify-content: flex-end;

      .icon {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-evenly;
        text-align: center;
        color: rgba(0, 0, 0, 0.5);
        background-color: white;
        transition: all .5s ease-in-out;
        cursor: pointer;
        width: 120px;
        height: 110px;
        margin: $space-sm;
        font-size: 12px;
        font-family: $din-medium;
        text-transform: uppercase;
        line-height: 14px;
        letter-spacing: 1.2px;
        position: relative;
        box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.05);

        a {
          @include link-overlay;
        }

        span {
          bottom: $space-sm;
          position: relative;
        }

        svg {
          fill: #7f7f7f;
          height: 25px;
          margin: auto;
          transition: all .5s ease-in-out;

          path {
            fill: #7f7f7f;
          }
        }

        &:hover {
          box-shadow: 0 5px $space-sm 0 rgba(0, 0, 0, 0.05);
          background-color: #FAFAFA;
          color: $link-blue;

          svg {
            fill: $link-blue !important;

            path {
              fill: $link-blue !important;
            }
          }
        }

        &.active {
          box-shadow: 0 5px $space-sm 0 rgba(0, 0, 0, 0.05);
          background-color: #FAFAFA;
          color: $link-blue;

          svg {
            fill: $link-blue !important;

            path {
              fill: $link-blue !important;
            }
          }
        }
      }
    }

    .copy {
      .cta {
        display: inline-block;
        text-decoration: underline;
        @include p-small;
        color: $link-blue;
      }
    }
  }

  .copyWithImage {
    .row {
      padding: 16px 0;

      .right {
        h3 {
          margin-bottom: 50px;
          position: relative;
          @include font-p-intro-blue;

          // &:after {
          //   content: "";
          //   position: absolute;
          //   bottom: -64px;
          //   left: 0;
          //   width: 200px;
          //   height: 1px;
          //   border-bottom: 1px solid #979797;
          //   margin-bottom: 32px;
          // }
        }
      }

      .left {
        img {
          width: 100%;
          margin-bottom: $space-lg;
        }

        .img {
          background-position: top right;
          background-repeat: no-repeat;
        }
      }
    }

  }

  .dualColumn{
    ul{
      li{
        @include p-base;
      }
    }
  }

  .teamModule {
    .row {
      padding: 32px 0;

      .right {
        h3 {
          &#memberName {
            position: relative;
            @include font-p-intro-blue;
            color: $ba-dark-gray;
          }
        }

        h4 {
          @include h4-caps(14px, $ba-dark-gray, $din-medium, 1.4px);
          position: relative;
          margin-bottom: 32px;
          display: block;

          &:after {
            content: "";
            position: absolute;
            bottom: -40px;
            left: 0;
            width: 135px;
            height: 1px;
            border-bottom: 1px solid #979797;
            margin-bottom: 32px;
          }
        }

        p {
          @include p-small;
          margin-bottom: $space-lg;
        }

        a {
          @include sidebar-small-link;
          font-weight: 100;
        }
      }

      .left {
        .img {
          width: auto;
          height: 242px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.17);
          margin-bottom: $space-lg;

          @media(min-width: 769px) {
            background-position: top right;
          }
        }
      }
    }
  }

  .copyWithGallery {
    h3 {
      margin-bottom: 100px;
      position: relative;
      @include font-p-intro-blue;

      &:after {
        content: "";
        position: absolute;
        bottom: -64px;
        left: 0;
        width: 200px;
        height: 1px;
        border-bottom: 1px solid #979797;
        margin-bottom: 32px;
      }
    }

    .gallery {
      // display: flex;
      // flex-wrap: wrap;

      .img {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        // float: left;
        min-height: 266px;
        margin-bottom: 32px;

        &.full {
          height: 423px;
        }
      }
    }
  }

  .team-member-cards {
    margin-bottom: 32px;
    padding: 0;

    h4 {
      @include h4-caps(14px, $ba-dark-gray, $din-medium, 1.4px);
      margin-bottom: 32px;
    }

    .row {
      .card {
        box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.13);
        background-color: $white;
        margin-bottom: 32px;
        position: relative;
        top: 0;
        transition: all .5s ease-in-out;

        a {
          @include link-overlay();
        }

        .img {
          height: 250px;
          width: 100%;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;

        }

        .name {
          padding: 24px 4px;
          @include h4-caps(14px, $link-blue, $din-medium, 1.4px);
          text-align: center;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 22px;
        }

        &:hover {
          -webkit-box-shadow: 0 15px 15px 0 rgba(0, 0, 0, .13);
          top: -4px;
        }
      }
    }

    @media(min-width: 992px) {
      margin-bottom: 90px;

      .row {
        .card {
          .img {
            height: 204px;
          }
        }
      }
    }
  }

  .careers {
    .row {
      border: 2px solid $link-blue;
      padding: $space-lg;

      h2 {
        @include h2-font($link-blue);
        text-transform: uppercase;
      }

      p {
        @include p-small;
      }

      a {
        @include sidebar-small-link;
        border-bottom: 0;
        text-decoration: underline;
        display:block;
        margin-bottom: 16px;
      }
    }
  }

  .careerWithCopy{
    .openings{
      padding: 24px 0;
    }
    a{
      @include sidebar-small-link;
      border-bottom: 0;
      display:block;
      margin-bottom: 16px;
      margin-left: 32px;
      font-size: 18px;
      position: relative;
      max-width: fit-content;
      span{
        position: relative;
        top: -2px;
      }
      &::after{
        content:"";
        border-bottom: 1px solid $link-blue;
        width: 100%;
        left: 0;
        bottom: 0;
        position: absolute;
      }
    }

    .copy{
      background-color: $white;
      padding: 24px 32px;
      margin: 16px;
      margin-top: 64px;
    }
  }

  .spotlight{
    .spotlight-content{
      background-color: $white;
      padding: 32px;
      .image{
        img{
          width: 100%;
        }
      }
      .copy{
        span{
          @include h2-font(#6f6f6f);
          display: block;
        }
        h4{
          position: relative;
          display: inline-block;
          margin-bottom: 32px !important;
          &:after{
            content:"";
            width: 80%;
            height: 1px;
            background-color: #979797;
            position: absolute;
            bottom: -8px;
            left: 0;
            right: 0;
            margin: auto;
          }
        }
      }
    }

    .spotlight-circle-images{
      display: flex;
      justify-content: space-between;
      .circle-img{
        background-position: center;
        background-size: cover;
        border-radius: 100%;
        height: 100px;
        width: 100px;
        display: inline-block;
        @media(min-width:992px){
          position: absolute;
          &:first-child{
            width: 242px;
            height: 242px;
            top: -30%;
            left: -10%;
            z-index: 3;
          }
          &:nth-child(2){
            width: 180px;
            height: 180px;
            top: 20%;
            left: 40%;
            z-index: 5;
          }
          &:last-child{
            width: 160px;
            height: 160px;
            top: 60%;
            left: -10%;
            z-index: 1;
          }
        }
      }
    }
  }

  .following{
    // background-color: $dark-gray;
    padding: 64px 0;
    h3{
      color:$ba-gray !important;
      font-size: 25px;
      margin-bottom: 64px !important;
      a.see-more{
        float: right;
        text-decoration: underline;
        color: #1B5191;
        font-size: 18px;
      }
    }
    .container{
      padding: 0 60px;
    }

    .row{
      display: grid;
      gap: 61px;
      grid-template-columns: auto auto auto;
      margin: 0;
    }

    .image{
 
      a{
        transition: all .4s ease-in-out;
        display: block;
        position: relative;
  
        &:after{
          content: "";
          height:100%;
          width: 100%;
          background: linear-gradient(180deg, transparent, rgba(0,0,0,0.7) 100%);
          top: 0;
          left: 0;
          position: absolute;
          z-index: 1;
          opacity: 0;
          transition: all .5s ease-in-out;
        }
  
        .img{
          background-size: cover;
          position: relative;
          height: 315px;
          width: 100%;

          span{
            opacity: 0;
            color:$white;
            position: absolute;
            bottom:  8px;
            left: 8px;
            z-index: 10;
            transition: all .5s ease-in-out;
          }
        }
  
        &:hover{
          &:after{
            opacity: 1;
          }
          span{
            opacity: 1;
          }
          box-shadow: 0 10px 14px 0 rgba(0,0,0,0.61);
          transform: scale(1.05);
        }
      }
  
    }
    }

}