.body-matrix{
  .two-up{
    p{
      text-align:justify;
    }
    padding-bottom: 32px;
    strong{
      @include h4-caps(14px, $ba-gray, $din-medium, 1.4px);
    }

    .left{
      height: 100%;
      div{
        background-color: #E2ECF8;
        font-size: 20px;
        padding: 16px;
      }
    }
    .right{
      height: 100%;
      div{
        font-size: 20px;
        padding: 16px;
      }
    }
    @media(min-width: 767px){
      padding: 30px 60px;
      padding-right: 115px;
    }
  }
  
  .textBlock{
    p{
      text-align:justify;
    }
    padding-top: 32px;
    padding-bottom: 32px;
    h4{
      @include h4-caps(14px, $ba-gray, $din-medium, 1.4px);
    }
    &:first-child{
      padding-top: 0;
    }
    @media(min-width: 767px){
      padding: 30px 60px;
      padding-right: 115px;
    }
  }
  
  .team-member-cards{
    margin-bottom: 32px;
    h4{
      @include h4-caps(14px, $ba-dark-gray, $din-medium, 1.4px);
      margin-bottom: 32px;
    }
    .row{
      .card{
        box-shadow: 0 7px 10px 0 rgba(0,0,0,0.13);
        background-color: $white;
        margin-bottom: 32px;
        a{
          @include link-overlay();
        }
        .img{
          height: 250px;
          width: 100%;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
      
        }
        .name{
          padding: 24px 0;
          @include h4-caps(14px, $link-blue, $din-medium, 1.4px);
          text-align: center;
        }
      }
    }
    @media(min-width: 992px){
      margin-bottom: 90px;
  
      .row{
        .card{
          .img{
            height: 204px;
          }
        }
      }
    }
  }
}
