.body-matrix {

  img{
    cursor: pointer;
  }

  //module globals
  h4{
    @include h4-caps(14px, $ba-gray, $din-medium, 1px);
  }

  .gallery {
    display: flex;
    flex-wrap: wrap;

    .img {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      float: left;
      height: 266px;
      margin-bottom: 16px;

      &.full {
        height: 423px;
        flex: 0 0 100%;
      }

      &.half {
        flex: 0 0 100%;

      }

      &.third {
        flex: 0 0 100%;

      }

      &.vertical{
        height: 600px;
      }

      @media(min-width:767px){
        padding: 0 60px;
      }

      @media(min-width:992px) {
        &.full {
          flex: 0 0 100%;
          &.vertical{
            height: 900px;
          }
        }

        &.half {
          flex: 0 0 49%;
          &.vertical{
            height: 500px;
          }

          &+div {
            margin-left: auto;
          }
        }

        &.third {
          flex: 0 0 36%;
          &.vertical{
            height: 400px;
          }

          &+div {
            margin-left: auto;
          }
        }
      }
    }
  }
  //module globals
  .specialFeatures{
    p{
      text-align:justify;
    }
    padding-top: 32px;
    padding-bottom: 32px;
    h4{
      @include h4-caps(14px, $ba-gray, $din-medium, 1px);
    }
    &:first-child{
      padding-top: 0;
    }
    .gallery{
      display: block;
    }
    img{
      display: inline-block;
      vertical-align: top;
      &.right{
        @media(min-width: 767px){
          float: right;
        }
      }
      &.half{
        width: 48%;
      }
      &.full{
        width: 100%;
      }
      @media(min-width: 767px){
        margin-bottom: 32px;
      }
    }
    @media(min-width: 767px){
      padding: 32px 60px;
    }
  }
  .constructionDetails{
    padding: 0;

    img{
      margin-bottom: 32px;
      display: inline-block;
      vertical-align: top;

      &.half{
        width: 48%;
      }

      &.full{
        width: 100%;
      }

      &.right{
        float: right;
      }
    }

    div{
      @media(min-width: 767px){
        padding: 0;
      }
    }

    @media(min-width: 767px){
      padding: 32px 60px;
    }
  }

  .floorplans {
    padding-top: 32px;
    padding-bottom: 32px;
    
    @media(min-width:767px){
      padding: 32px 60px;   
    }
    a{
      img {
        display: inline-block;
        box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.08);
        width: 100%;
        margin-bottom: 32px;
        &.right {
          @media(min-width: 767px){
            float: right;
          }
        }
        &.first{
          @media(min-width: 767px){
            float: left;
          }
        }
        &.full{
          width: 100%;
        }
        @media(min-width: 1024px){
          &.half{
            width: 48%;
          }
        }
      }
    }
  }

  .quote {
    padding-top: 32px;
    padding-bottom: 32px;

    div {
      background-color: $charcoal;
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.07);
      padding: 64px;
      padding-bottom: 48px;
      position: relative;
      width: 80%;

      // &:before {
      //   content: "";
      //   background-color: #D0021B;
      //   border-radius: 100%;
      //   width: 14px;
      //   height: 14px;
      //   position: absolute;
      //   top: 16px;
      //   left: 0;
      //   right: 0;
      //   margin: auto;
      //   text-align: center;
      //   box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      // }

      span {
        display: block;
        margin-bottom: 32px;
        position: relative;

        &:before {
          content: "“";
          position: absolute;
          top: -20px;
          left: -30px;
          transform: scale(5);
        }

        p {
          @include p-base;
          font-size: 20px;
          line-height: 33px;
          text-align: start;
          color: $ba-gray;
        }
      }

      p {
        @include font-p-intro;
        font-size: 20px;
        line-height: 33px;
        text-align: start;
        color: $white;;
      }
    }
  }
}