#homepage {
  overflow: hidden;
  header#header {
    @include header-mobile-styles;
  }
  main {
    padding: 0;
    position: relative;
    .bg {
      @include bg-full-screen;
      a{
        @include link-overlay;
      }
      .bg-icons{
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 100%;
        background: linear-gradient(180deg, rgba(59,52,24,0) 0%, #463E2B 90%);
        z-index: 1;
        .col-md-6{
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
        .project-title{
          @include h4-caps(16px, $white, $din-medium, 1.6px);
          text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
          text-align: center;
          margin-bottom: 190px;
          padding: 0 32px;
        }

        @media(min-width: 500px){
          padding:100px 64px;
          .project-title{
            @include h4-caps(16px, $white, $din-medium, 1.6px);
            text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
            text-align: right;
            margin-bottom: 0px;
            padding: 0;
          }
        }
      }
    }
    .icons{
      position: absolute;
      bottom: 125px;
      left: 0;
      right: 0;
      margin: auto;
      width: 90%;
      display: flex;
      color: white;
      text-transform: uppercase;
      padding: 0 16px;
      justify-content: space-between;
      .icon{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        opacity: .5;
        transition: all 1s ease-in-out;
        cursor: pointer;
        font-size: 9px;
        letter-spacing: 0.61px;
        line-height: 10px;
        position: relative;
        z-index: 100;
        
        a{
          @include link-overlay;
        }
        svg{
          height: 40px;
          fill: #ffffff !important;
          path{
            fill: $white !important;
          }
        }
        &.active{
          opacity: 1;
        }
      }
      
      @media(min-width: 500px){
        width: 55%;
        padding: 90px 64px;
        bottom: 0;
        margin-right: 32px;
        right: initial;

        &:first-child{
          margin-left: 64px;
        }
      }
      @media(min-width:767px){
        width: 440px;
      }
      @media(min-width:1025px){
        width: 460px;
        left: 24px;
      }
    }
  }
  #news-teaser {
    display: none;
    color: $light-gray;
    font-size: 20px;
    line-height: 30px;
    background-color: rgba(0,0,0,0.5);
    position: absolute;
    top: -30px;
    left: 0;
    height: 30px;
    width: 165px;
    padding: 0 20px;
    margin: 0;
    text-align: center;
    cursor: pointer;
  }
  #recent-news {
    display: none;
    max-height: 0;
    padding-top: $space-sm;
    position: relative;
    z-index: 1;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    overflow: hidden;
    .news-wrapper {
      padding-top: $space-md;
      width: 100%;
      display: flex;
      .image {
        width: 20%;
        padding-right: $space-md;
        img {
          display: block;
          max-width: 100%;
        }
      }
      .text {
        width: 80%;
        h4 {
          font-size: 24px;
          color: $white;
        }
        p {
          font-size: 14px;
          line-height: 18px;
          color: $light-gray;
          a {
            color: $fog;
            text-decoration: underline;
            &:hover {
              color: $white;
            }
          }
        }
      }
    }
  }
  .social{
    display: none;
  }
  footer{
    display: none;
  }
}
