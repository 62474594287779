#about {
  h3#team{
    @media(min-width: 992px){
      padding: 0 60px;
    }
  }
  header#header {
    position: relative;
    ul#nav-about {
      @include nav-about;
    }
    @media(max-width: 767px){
      margin-bottom: 0 !important;

    }
  }
  .banner{
    background-color: $link-blue;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
      &:after {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(0deg, rgba(37,47,65,0) 0%, rgba(30,54,96,0.45) 100%);
      }
      @media(min-width: 767px){
        height: 600px;
      }
  }
  .content{
    .container{
      margin-bottom: 32px;

      &.team-member-cards{
        @media(min-width:767px){
          &.half{
            width: 49%;
            // float: left;
            padding-right: 15px !important;
            display: inline-block;
          }
        }

      }

      .no-sidebar{
        padding-top: 32px;

        p{
          font-size: 25px;
          line-height: 33px;
          color: $dark-gray;
          text-align: left;
        }
      
        .back-button{
          @include media-breakpoint-up(md){
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .bt{
              margin-bottom: 24px;
            }
          }
        }
      }
    }
    h3{
      @include project-title($ba-dark-gray);
      position: relative;
      margin-bottom: $space-md;
    }
     h4{
      margin-bottom: $space-md;
    }
    .details {
      padding: 0px;
      background-color: #FAFAFA;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.06);
      position: relative;
      .down-arrow{
        position: absolute;
        right: 64px;
        top: 32px;
        left: initial;
        i{
          color: $ba-gray;
        }
      }
      //  @media(min-width: 992px) {
      //     padding: 30px 60px;
      //   }
      
      .content-intro {
        padding: 0 60px;

        h1{
          margin-top: 32px;
        }

        .bold-intro{
          h1{
            font-family: $din-medium;
          }
          p{
            @include font-p-intro;
          }

        }
        h1{
          @include project-title($ba-dark-gray);
          position: relative;
          margin-bottom: 24px;
          // &:before {
          //   content: "";
          //   position: absolute;
          //   bottom: -32px;
          //   left: 0px;
          //   width: 120px;
          //   height: 3px;
          //   background-color: $link-blue;
          // }
        }
      }

      p{
        text-align: justify;
      }

      @media(min-width: 767px){
        margin-top: -$space-xl;

      }
      @media(min-width: 992px) {        
        // .row{
        //   .img{
        //     height: 242px;
        //   }
        // }
      }
    }

    .sidebar {
      margin-top: $space-lg;
      padding-right: 0;
      ul.awards{
        @include list-no-style;
        li{
          @include sidebar-small-link(); 
          margin: 0;
          font-family: $din-light;
          a{
            color: $link-blue;
            font-size: 18px;
            text-decoration: none;
            padding: 4px 8px;
            display: block;
            &:hover {
              background: white;
              text-decoration: none;
            }
          line-height: 22px;
          @media(max-width: 1199px){
            font-size: 14px;
            line-height: 18px;
          }
          }
        }
      }
      h4{
        @include h4-caps(14px, $ba-dark-gray, $din-medium, 1px);
        margin-top: $space-lg;
        margin-bottom: $space-sm;
      }
      p{
        @include p-link;
        margin: 0;
        font-family: $din-light;

        .link{
         @include sidebar-small-link(); 
         font-family: $din-light;
         color: $link-blue;
         font-size: 18px;
         line-height: 22px;
         transition: all .25s ease;
         padding: 4px 8px;
         &:hover {
          background: white;
          text-decoration: none;
        }
          &.active{
            color: $ba-dark-gray;
            border-bottom: none;
          }
        }
      }
    }
  }
  .main-content {
    margin: $space-lg 0;
  }
  @include page-images;
}
