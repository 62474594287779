@charset "UTF-8";

/*
    Colors:
*/

$white: #ffffff;
$black: #000000;
$charcoal: #222;
$light-charcoal: #555558;
$theme-dark: #8a9199;
$dark-gray: #4d4d4f;
$medium-gray: #a0a0a0;
$light-gray: #bdbdbd;
$lightest-gray: #f7f7f7;
$fog: #efefef;
$silver: #9ba5ae;
$dark-silver: #566675;
$smoke: #ccc; //off white
$light-purple: #6279b7;
$red: #d9534f;
$link-blue: #465a69;
$background-gray: #f0f0f0;
$error: #ad1f31;
$ba-dark-gray: #4b4b4b;
$ba-mid-gray: #6f6f6f;
$ba-gray: #a4a4a4;
$light-blue: #2D8EFF;
$dark-blue: #133a67;

/*
    Font Styles:
*/

@mixin p-base () {
  font-size: 18px;
  line-height: 28px;
  text-align:start;
  color: #6f6f6f;
  font-style: normal;
  font-family: $nunito-reg;
  font-weight: 300;
}

@mixin font-p-intro () {
  font-size: 25px;
  line-height: 33px;
  text-align: start;
  color: #4b4b4b;
  font-style: normal;
  font-family: $nunito-reg;
}

@mixin font-p-intro-blue () {
  font-size: 25px;
  line-height: 33px;
  text-align: start;
  color: $link-blue;
  font-style: normal;
  font-family: $nunito-reg;
}

@mixin p-small () {
  font-size: 18px;
  line-height: 30px;
  text-align: start;
  color: #6f6f6f;
  font-style: normal;
  font-family: $nunito-reg;
}

@mixin p-small-footer ($line-height) {
  font-size: 16px;
  line-height: $line-height;
  text-align: start;
  color: rgba($light-gray, 0.6);
  font-style: normal;
  font-family: $nunito-reg;
  @include media-breakpoint-down(lg) {
      font-size: 14px;
  }
}

@mixin sidebar-small-link () {
  font-size: 18px;
  line-height: normal;
  text-align: start;
  color: $link-blue;
  text-decoration: none;
  font-style: normal;
  line-height: 16px;
  display: inline-block;
  @include media-breakpoint-down(lg) {
      font-size: 14px;
      line-height: 14px;
  }
}

@mixin p-link () {
  font-size:20px;
  line-height: normal;
  text-align:  start;
  color: $link-blue;
  font-style: normal;
}

@mixin project-title ($color) {
  font-size: 31px;
  line-height: normal;
  text-align: start;
  color: $color;
  letter-spacing: 0.35px;
  font-style: normal;
  font-family: $din-light;
}

@mixin people-title ($color) {
  font-size: 31px;
  line-height: normal;
  text-align: left;
  color: $color;
  letter-spacing: 0.35px;
  font-style: normal;
  font-family: $din-bold;
}

@mixin h2-font($color) {
  font-size: 25px;
  line-height: 33px;
  text-align: start;
  color: $color;
  font-style: normal;
  font-family: $nunito-reg;
}

@mixin h3-caps-blue () {
  font-size: 22px;
  line-height: normal;
  text-align:  start;
  color: $link-blue;
  letter-spacing: 4.1px;
  text-transform: uppercase;
  font-style: normal;
  font-family: $din-medium;
}

@mixin h3-font($color, $font) {
  font-size: 22px;
  line-height: normal;
  text-align: start;
  color: $color;
  font-style: normal;
  font-family: $font;
}

@mixin h4-caps ($size, $color, $font, $letter-spacing) {
  font-size: $size;
  line-height: normal;
  color: $color;
  letter-spacing: $letter-spacing;
  text-transform: uppercase;
  font-style: normal;
  font-family: $font;
}

@mixin h4-sidebar-link () {
  font-size: 18px;
  line-height: 26px;
  text-align: left;
  color: $link-blue;
  text-decoration: underline;
  font-style: normal;
  font-family: $din-medium;
  cursor: pointer;
}

@mixin h5-caps-red () {
  font-size: 14px;
  line-height: normal;
  text-align: start;
  color: #ad1f31;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  font-style: normal;
  font-family: $din-medium;
}

@mixin h5-bold () {
  font-size: 14px;
  line-height: 24px;
  text-align: left;
  color: $link-blue;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  font-style: $din-bold;
  font-family: $din-bold;
}

@mixin h5-all-caps-base () {
  font-size: 14px;
  line-height: normal;
  text-align: start;
  color: #4a4a4a;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  font-style: normal;
  font-family: $din-medium;
}

@mixin h5-caps-links () {
  font-size: 14px;
  line-height: 26px;
  text-align: start;
  color: $link-blue;
  letter-spacing: 1.62px;
  text-transform: uppercase;
  font-style: normal;
  font-family: $din-light;
  cursor: pointer;
}

@mixin h6-caps ($size, $color, $font, $letter-spacing) {
  font-size: $size;
  line-height: 24px;
  text-align: left;
  color: $color;
  letter-spacing: $letter-spacing;
  text-transform: uppercase;
  font-style: normal;
  font-family: $font;
}
@mixin menu ($color) {
  font-family: $din-light;
  color: $color;
  letter-spacing: 1.18px;
  line-height: normal;
  font-size: 16px;
}

@mixin label($color){
  font-family: $din-medium;
  display: block;
  margin-top: $space-md;
  font-size: 14px;
  letter-spacing: 1.18px;
  line-height: 18px;
  color: $color;
  text-transform: uppercase;
}

// spacing
$space-inset-xs: 0 4px;
$space-inset-sm: 0 8px;
$space-inset-md: 0 16px;
$space-inset-lg: 0 32px;
$space-inset-xl: 0 64px;
$space-stack-xs: 4px 0;
$space-stack-sm: 8px 0;
$space-stack-md: 16px 0;
$space-stack-lg: 32px 0;
$space-stack-xl: 64px 0;
$space-xs: 4px;
$space-sm: 8px;
$space-md: 16px;
$space-lg: 32px;
$space-xl: 64px;

// typography
$line-height: 1.2em;
